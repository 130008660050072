var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mb-5"},[_c('CCol',{attrs:{"lg":"3"}},[_c('TAside',[_c('CListGroup',[_c('CListGroupItem',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"div","color":"success"},on:{"click":function($event){_vm.showModal = true}}},[_c('TMessage',{attrs:{"content":"New combine box","bold":"","alignment":"center"},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-plus"}})]},proxy:true}])})],1),_c('CListGroupItem',[_c('TInputSimpleSearch',{attrs:{"placeholder":"Search sku","value":_vm.search},on:{"update:value":function($event){_vm.search=$event}}})],1),_vm._l((_vm.boxes_available),function(box,index){return _c('CListGroupItem',{key:`${index}${box.id}`,attrs:{"color":_vm.box_id_selected == box.id ? 'primary' : 'secondary'}},[_c('SCardBox',{staticClass:"cursor-pointer",attrs:{"box":box,"parent":""},nativeOn:{"click":function($event){return _vm.lodash.appendRouteQuery(_vm.$router, {
                query: { box_id: box.id },
              })}}})],1)}),(!_vm.boxes_available.length)?_c('CListGroupItem',[_c('TMessageNotFound')],1):_vm._e(),(_vm.boxes_available_loading)?_c('CListGroupItem',[_c('TSpinner',{attrs:{"loading":_vm.boxes_available_loading}})],1):_vm._e(),_c('SButtonLoadmore',{attrs:{"store":"warehouse.boxes_parent"}})],2)],1)],1),_c('SModalCreateSku',{attrs:{"show":_vm.showModal,"createParentSku":""},on:{"update:show":function($event){_vm.showModal=$event},"created":_vm.newParentBox}}),_c('CCol',{attrs:{"lg":"9"}},[_c('CRow',[(_vm.box_id_selected)?_c('CCol',{attrs:{"col":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Combine SKUs to","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" "+_vm._s(_vm.box_id_selected || "...")+" ")]},proxy:true}],null,false,4008721208)})],1)]),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('SInputSku',{on:{"enter":_vm.enterSku}}),_c('div',{staticClass:"scroll-x"},[_c('div',{staticClass:"d-inline-flex"},_vm._l((_vm.listMergeBox),function(box){return _c('SCardSku',{key:box.id,staticClass:"my-3 mx-3",attrs:{"box":box,"focus-quantity":box.quantity,"focus-quantity-ediable":true,"removable":true,"removing":box.removing,"noItems":"","noConfirmOnRemove":""},on:{"remove":function($event){return _vm.removeBox(box)}}})}),1)])],1)],1)],1),(_vm.listMergeBox.length)?_c('CCardFooter',{staticClass:"d-flex"},[_c('TButton',{staticClass:"mx-auto",attrs:{"color":"success","messageOptions":{
                bold: true,
                noTranslate: true,
              },"options":{
                disabled:
                  (_vm.listMergeBox.length && _vm.lodash.isEmpty(_vm.box_id_selected)) ||
                  _vm.loading,
              },"icon":"cil-data-transfer-down"},on:{"click":_vm.mergeBoxes},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('TMessage',{staticClass:"ml-2",attrs:{"content":"Merge boxes","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [(_vm.listMergeBox.length)?_c('span',[_vm._v(" "+_vm._s(`(${_vm.listMergeBox.length} ${_vm.$t("boxes")})`)+" ")]):_vm._e()]},proxy:true}],null,false,4168013328)})]},proxy:true}],null,false,425589744)})],1):_vm._e()],1)],1):_vm._e(),(_vm.box_id_selected)?_c('CCol',{attrs:{"col":"12"}},[(_vm.box_selected.children && _vm.box_selected.children.length)?_c('CCard',[_c('CCardHeader',{staticClass:"d-flex flex-wrap align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"SKUs in","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.box_id_selected || "..."))])]},proxy:true}],null,false,1185507800)}),_c('TSpinner',{attrs:{"loading":_vm.boxes_detail_loading}})],1),_c('TButtonRefresh',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.boxes_available_detail_loading }},on:{"click":_vm.fetchBoxSelected}})],1),_c('CCardBody',[(!_vm.box_selected.children.length)?_c('TMessageNoData',{attrs:{"content":"No SKU"}}):_c('CRow',{staticClass:"d-flex align-items-stretch"},_vm._l((_vm.box_selected.children),function(box){return _c('SCardSku',{key:box.id,staticClass:"my-3 mx-3",attrs:{"box":box,"refresh":"","removable":""},on:{"remove":function($event){return _vm.removeChidren(box)}}})}),1),_c('SButtonLoadmore',{attrs:{"store":"warehouse.boxes_ref_decouple"}})],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }