<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem
            tag="div"
            style="cursor: pointer"
            @click="showModal = true"
            color="success"
          >
            <TMessage content="New combine box" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <TInputSimpleSearch placeholder="Search sku" :value.sync="search" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in boxes_available"
            :key="`${index}${box.id}`"
            :color="box_id_selected == box.id ? 'primary' : 'secondary'"
          >
            <SCardBox
              class="cursor-pointer"
              @click.native="
                lodash.appendRouteQuery($router, {
                  query: { box_id: box.id },
                })
              "
              :box="box"
              parent
            >
            </SCardBox>
          </CListGroupItem>
          <CListGroupItem v-if="!boxes_available.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="boxes_available_loading">
            <TSpinner :loading="boxes_available_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.boxes_parent" />
        </CListGroup>
      </TAside>
    </CCol>
    <SModalCreateSku
      :show.sync="showModal"
      @created="newParentBox"
      createParentSku
    />
    <CCol lg="9">
      <CRow>
        <CCol col="12" v-if="box_id_selected">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center">
                <TMessage content="Combine SKUs to" bold>
                  <template #suffix> {{ box_id_selected || "..." }} </template>
                </TMessage>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md="12" class="mb-3">
                  <SInputSku @enter="enterSku" />
                  <!-- + input pallet -->
                  <div class="scroll-x">
                    <div class="d-inline-flex">
                      <SCardSku
                        v-for="box in listMergeBox"
                        :key="box.id"
                        :box="box"
                        class="my-3 mx-3"
                        :focus-quantity="box.quantity"
                        :focus-quantity-ediable="true"
                        :removable="true"
                        @remove="removeBox(box)"
                        :removing="box.removing"
                        noItems
                        noConfirmOnRemove
                      >
                      </SCardSku>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter class="d-flex" v-if="listMergeBox.length">
              <TButton
                color="success"
                :messageOptions="{
                  bold: true,
                  noTranslate: true,
                }"
                :options="{
                  disabled:
                    (listMergeBox.length && lodash.isEmpty(box_id_selected)) ||
                    loading,
                }"
                icon="cil-data-transfer-down"
                class="mx-auto"
                @click="mergeBoxes"
              >
                <template #content>
                  <TMessage content="Merge boxes" bold class="ml-2">
                    <template #suffix>
                      <span v-if="listMergeBox.length">
                        {{ `(${listMergeBox.length} ${$t("boxes")})` }}
                      </span>
                    </template>
                  </TMessage>
                </template>
              </TButton>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol col="12" v-if="box_id_selected">
          <CCard v-if="box_selected.children && box_selected.children.length">
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage content="SKUs in" bold>
                  <template #suffix>
                    <span class="ml-1">{{ box_id_selected || "..." }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="boxes_detail_loading" />
              </div>
              <TButtonRefresh
                class="ml-auto"
                @click="fetchBoxSelected"
                :options="{ disabled: boxes_available_detail_loading }"
              />
            </CCardHeader>
            <CCardBody>
              <TMessageNoData
                content="No SKU"
                v-if="!box_selected.children.length"
              />
              <CRow v-else class="d-flex align-items-stretch">
                <SCardSku
                  v-for="box in box_selected.children"
                  :key="box.id"
                  :box="box"
                  refresh
                  class="my-3 mx-3"
                  removable
                  @remove="removeChidren(box)"
                />
              </CRow>
              <SButtonLoadmore store="warehouse.boxes_ref_decouple" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import searchable from "../../mixins/searchable";
import audio from "../../mixins/audio";
import barcode from "../../mixins/barcode";
import label from "../../mixins/label";

export default {
  mixins: [searchable, label, audio, barcode],
  data() {
    return {
      listMergeBox: [],
      showModal: false,
    };
  },
  watch: {
    box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.fetchBoxSelected();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      boxes_available: "warehouse.boxes_parent.list",
      boxes_available_loading: "warehouse.boxes_parent.loading",
      boxes_available_detail_loading: "warehouse.boxes_parent.detail.loading",
      boxes_detail_loading: "warehouse.boxes_parent.detail.loading",
      loading: "warehouse.merge_package.detail.loading",
    }),
    box_id_selected() {
      return this.$route.query.box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.boxes_parent.detail"];
    },
  },
  methods: {
    fetchBoxSelected() {
      this.$store.dispatch(
        "warehouse.boxes_parent.detail.fetch",
        this.box_id_selected
      );
    },
    triggerSearch(value) {
      if (value || value == "") {
        this.$store.dispatch("warehouse.boxes_parent.apply-query", {
          "filter[id]": value,
        });
      } else this.$store.dispatch("warehouse.boxes_parent.fetch.if-first-time");
    },
    pickBarcode(e) {
      const code = this.press(e);
      if (code) {
        this.enterSku(code);
      }
    },
    enterSku(sku) {
      const existed = this.listMergeBox.find((x) => x.id == sku);
      if (!sku || !this.box_id_selected || this.showModal) return;
      if (existed) return;
      else {
        this.$store
          .dispatch("warehouse.boxes_freedom.detail.fetch", sku)
          .then((data) => {
            this.listMergeBox.push(data);
            this.playSuccess();
          })
          .catch(() => {
            this.playFail();
          });
      }
    },
    removeChidren(box) {
      this.$store
        .dispatch("warehouse.boxes_management.update", {
          id: box.id,
          attributes: {
            box_parent_id: "",
          },
        })
        .then(() => {
          this.fetchBoxSelected();
        });
    },
    removeBox(box) {
      const index = this.listMergeBox.findIndex((x) => x.id == box.id);
      this.listMergeBox.splice(index, 1);
    },
    mergeBoxes() {
      const list = this.listMergeBox.map((x) => x.id);
      this.$store
        .dispatch("warehouse.merge_package.detail.merge", {
          box_parent_id: this.box_id_selected,
          box_ids: list,
        })
        .then(() => {
          this.listMergeBox = [];
          this.fetchBoxSelected();
        });
    },
    newParentBox(data) {
      this.showModal = false;
      this.$router.push({
        query: { ...this.$route.query, box_id: data.id },
      });
      setTimeout(() => {
        this.triggerSearch();
      }, 300);
      const message = {
        ...this.message,
        type: "SKU",
        sku: data.id,
        quantity: data.duplicate,
        itemQuantity: 0,
      };
      this.$store.dispatch("printer.send-message", JSON.stringify(message));
    },
  },
};
</script>
